import React, { useState, useEffect, useCallback } from 'react';

// Importa componentes de Bootstrap que usarás
import { Container, Row, Col, Pagination } from 'react-bootstrap';
import SearchForm from '../components/SearchForm/SearchForm';
import FlightCard from '../components/FlightCard/FlightCard';
import { apiEndpoints } from '../apiConfig';

const FlightList = () => {
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const flightsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredFlights, setFilteredFlights] = useState(flights);
  const [totalFlights, setTotalFlights] = useState(100);  // Comienza con 100 y aumenta conforme se soliciten más vuelos
  const [currentSearchParams, setCurrentSearchParams] = useState({
    departure: '',
    arrival: '',
    Date: '',
  });
  
  const paginate = (pageNumber) => {
    if (pageNumber === '...') return; // Si el usuario hace clic en los puntos suspensivos, no hagas nada.
    setCurrentPage(pageNumber);
  };
  

  // Renderiza la paginación
  const renderPagination = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(filteredFlights.length / flightsPerPage);
    const pageWindow = 3; // Número de páginas a mostrar alrededor de la página actual
    let startPage, endPage;
  
    if (currentPage <= pageWindow + 1) {
      startPage = 1;
      endPage = Math.min(2 * pageWindow + 1, totalPages);
    } else if (currentPage + pageWindow > totalPages) {
      startPage = Math.max(1, totalPages - 2 * pageWindow);
      endPage = totalPages;
    } else {
      startPage = currentPage - pageWindow;
      endPage = currentPage + pageWindow;
    }
  
    // Siempre agrega la primera página si tu rango de inicio es mayor que 2
    if (startPage > 2) {
      pageNumbers.push(1, '...');
    } else if (startPage === 2) {
      pageNumbers.push(1);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    // Siempre agrega la última página si tu rango de fin es menor que totalPages - 1
    if (endPage < totalPages - 1) {
      pageNumbers.push('...', totalPages);
    } else if (endPage === totalPages - 1) {
      pageNumbers.push(totalPages);
    }
  
    return (
      <Pagination className="justify-content-end">
        {pageNumbers.map((number, index) => (
          <Pagination.Item key={index} active={number === currentPage} onClick={() => paginate(number)}>
            {number}
          </Pagination.Item>
        ))}
      </Pagination>
    );
  };

  const renderLoadMoreButton = () => {
    const totalPages = Math.ceil(filteredFlights.length / flightsPerPage);
    if (currentPage === totalPages && filteredFlights.length >= totalFlights) {
      return (
        <Row className="justify-content-center mb-4">
          <Col md={4} className="text-center">
            <button className="btn btn-primary" onClick={loadMoreFlights}>Cargar más vuelos</button>
          </Col>
        </Row>
      );
    }
    return null;
  };
  

  const handleSearch = useCallback(async (searchParams) => {
    setCurrentSearchParams(searchParams);
    setCurrentPage(1);
    // Convertir parámetros de búsqueda en una consulta de URL
    const params = new URLSearchParams({
      page: 1, // siempre reiniciar a la primera página para nuevas búsquedas
      count: totalFlights,
      departure: searchParams.departure || '',
      arrival: searchParams.arrival || '',
      Date: searchParams.Date || '',
    });
  
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiEndpoints.AllFlights}?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) {
        throw new Error('Something went wrong, status ' + response.status);
      }
      const data = await response.json();
      setFlights(data);
      setFilteredFlights(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [totalFlights]);

  useEffect(() => {
    handleSearch({ departure: '', arrival: '', Date: '' }); // Carga inicial con parámetros vacíos
  }, [handleSearch]);
  
  const loadMoreFlights = () => {
    setTotalFlights(current => current + 100);  // Aumentar en bloques de 100, o el número que consideres adecuado
    handleSearch(currentSearchParams);  // Suponiendo que tienes acceso a los parámetros de búsqueda actuales
  };
  

  return (
    <Container>
      <SearchForm onSearch={handleSearch} />
      <h1 className="mt-4">Vuelos Disponibles</h1>
      {loading ? (
        <p>Cargando vuelos...</p>
      ) : error ? (
        <p>Error al cargar los vuelos: {error}</p>
      ) : (
        <>
          {filteredFlights.length > 0 ? (
            <Row>
              {filteredFlights.slice((currentPage - 1) * flightsPerPage, currentPage * flightsPerPage).map(flight => (
                <Col key={flight.id} md={12}>
                  <FlightCard flight={flight} />
                </Col>
              ))}
            </Row>
          ) : (
            <Row>
              <Col className="text-center">
                <p>No se obtuvieron resultados para tu búsqueda :(</p>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div style={{ marginTop: '20px', marginBottom: '20px' }}></div>
              {filteredFlights.length > flightsPerPage && renderPagination()}
            </Col>
          </Row>
            {renderLoadMoreButton()}
        </>
      )}
    </Container>
  );
};

export default FlightList;