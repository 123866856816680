import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import './HomePage.css';

const HomePage = () => {
  const sectionStyle = {
    width: "100%",
    height: "100vh",
    backgroundImage: 'url(/venezia.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  };



  return (
    <div style={sectionStyle}>
      <Row className="justify-content-md-center align-items-start pt-5">
        <Col md={6} className="text-center">
          <h1 className="display-3 fw-bold" style={{ color: 'black' }}>Bienvenido a Vuelify!</h1>
          <p className="fs-4" style={{ color: 'black' }}>La mejor manera de buscar y reservar tus vuelos.</p>
          <Button
            className="custom-button"
            size="lg"
            href="/flights"
          >
            Buscar Vuelos
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default HomePage;