import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import './SearchForm.css';

const SearchForm = ({ onSearch }) => {
  const [searchParams, setSearchParams] = useState({
    departure: '',
    arrival: '',
    Date: '',
  });

  // Este efecto se ejecuta cada vez que searchParams cambia.
  useEffect(() => {
    // Evita ejecutar la búsqueda con campos vacíos.
    if (searchParams.departure || searchParams.arrival || searchParams.Date) {
      onSearch(searchParams);
    }
  }, [searchParams, onSearch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedValue = value.toUpperCase(); // Convierte el texto a mayúsculas
    setSearchParams(prevParams => ({
      ...prevParams,
      [name]: updatedValue
    }));
  };
  

  return (
    <Form>
      <Row className="mb-3">
        <Col>
          <h2>Exploremos en base a tus preferencias!</h2>
          <p>Encuentra tu próximo avión fácilmente filtrando según tus preferencias de viaje.</p>
        </Col>
      </Row>
      <Row className="filter-container align-items-center">
        <Col md={4}>
          <Form.Group controlId="departure">
            <Form.Label>Aeropuerto de Salida</Form.Label>
            <Form.Control
              type="text"
              name="departure"
              value={searchParams.departure}
              onChange={handleChange}
              placeholder="¿Desde dónde?"
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="arrival">
            <Form.Label>Aeropuerto de Llegada</Form.Label>
            <Form.Control
              type="text"
              name="arrival"
              value={searchParams.arrival}
              onChange={handleChange}
              placeholder="¿A dónde quieres ir?"
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="Date">
            <Form.Label>Fecha de Salida</Form.Label>
            <Form.Control
              type="date"
              name="Date"
              value={searchParams.Date}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchForm;
