import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Modal, Form, Image } from 'react-bootstrap';
//import { useNavigate } from 'react-router-dom';
import { apiEndpoints } from '../apiConfig';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from '../components/Auth0/LoginButton';


const FlightDetail = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { id } = useParams();  // Obtener el ID del vuelo desde la URL
  const [flight, setFlight] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [seats, setSeats] = useState(1); // Por defecto, 1 asiento

  // let navigate = useNavigate();

  useEffect(() => {
    const fetchFlight = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(apiEndpoints.FlightInfo(id));
        if (!response.ok) {
          throw new Error('Failed to fetch flight details');
        }
        const data = await response.json();
        setFlight(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFlight();
  }, [id]);

  const handleBookNow = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmReservation = () => {
    setConfirmationModal(true);
    setShowModal(false);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const handleConfirmBooking = async () => {

    try {
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const json = await ipResponse.json();
      const ip = json.ip;
      const token = await getAccessTokenSilently();
      const postData = {
        departure_airport: flight.departure_airport_id,
        arrival_airport: flight.arrival_airport_id,
        departure_time: flight.departure_airport_time,
        quantity: seats
      };
      const response = await fetch(apiEndpoints.PostRequest, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Origin': JSON.stringify(ip),
          'Content-Type': 'application/json',
          'X-Forwarded-For': JSON.stringify(ip)
        },
        body: JSON.stringify(postData),
      });
      const response_back = await response.json();
      console.log(response_back);

      // Form para redireccionamiento a WebPay

      const form = document.createElement('form');
      form.method = 'post';
      form.action = response_back.url;

      const tokenInput = document.createElement('input');
      tokenInput.type = 'hidden';
      tokenInput.name = 'token_ws';
      tokenInput.value = response_back.token;
      form.appendChild(tokenInput);

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      console.error('Error al enviar la reserva:', error.message);
    }
    handleCloseConfirmationModal();

    // Redireccionamiento a my-puchases es manejado por Backend
  };

  const handleSeatChange = (event) => {
    setSeats(event.target.value);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Intl.DateTimeFormat('es-ES', options).format(new Date(dateString));
  };

  const formatCurrency = (price, currency) => {
    return new Intl.NumberFormat('es-ES', { style: 'currency', currency: currency }).format(price);
  };

  if (loading) return <p style={{ textAlign: 'center', margin: '50px 0' }}>Cargando detalles del vuelo...</p>;
  if (error) return <p style={{ textAlign: 'center', margin: '50px 0' }}>No se encontró el vuelo :(</p>;
  if (!flight) return <p style={{ textAlign: 'center', margin: '50px 0' }}>No se encontró el vuelo :(</p>;

  return (
    <Container className="mt-4">
      <Card>
        <Card.Header as="h5">
          <Image src={flight.airline_logo} roundedCircle />
          {' '}{flight.airline} - Vuelo {flight.id}
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={6}><strong>Desde:</strong> {flight.departure_airport_name} ({flight.departure_airport_id})</Col>
            <Col md={6}><strong>Hasta:</strong> {flight.arrival_airport_name} ({flight.arrival_airport_id})</Col>
          </Row>
          <Row className="mt-2">
            <Col md={6}><strong>Salida:</strong> {formatDate(flight.departure_airport_time)}</Col>
            <Col md={6}><strong>Llegada:</strong> {formatDate(flight.arrival_airport_time)}</Col>
          </Row>
          <Row className="mt-2">
            <Col><strong>Duración:</strong> {Math.floor(flight.duration / 60)}h {flight.duration % 60}m</Col>
          </Row>
          <Row className="mt-2">
            <Col><strong>Avión:</strong> {flight.airplane}</Col>
          </Row>
          <Row className="mt-3">
            <Col><strong>Precio:</strong> {formatCurrency(flight.price, flight.currency)}</Col>
          </Row>
          <Row className="mt-2">
            <Col><strong>Emisión de CO2:</strong> {flight.carbon_emission} g</Col>
          </Row>
        </Card.Body>
        <Card.Footer className='d-flex justify-content-between align-items-center'>
          <div>Quedan {flight.seats_available} asientos disponibles en este vuelo</div>
          <Button variant="primary" onClick={handleBookNow}>Reservar Ahora</Button>
        </Card.Footer>
      </Card>

      {/* Modal para seleccionar la cantidad de asientos */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reserva de Asientos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isAuthenticated ? (
            <Form>
              <Form.Group>
                <Form.Label>Cantidad de Asientos</Form.Label>
                <Form.Control
                  type="number"
                  value={seats}
                  onChange={handleSeatChange}
                  min="1"
                  max={flight.seats_available >= 4 ? 4 : flight.seats_available} />
              </Form.Group>
            </Form>
          ) : (
            <div>
              <p>Debes iniciar sesión en Vuelify para realizar una reserva.</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isAuthenticated ? (
            <React.Fragment>
              <Button variant="secondary" onClick={handleCloseModal}>Atrás</Button>
              <Button variant="primary" onClick={handleConfirmReservation}>Reservar</Button>
            </React.Fragment>
          ) : (
            <LoginButton />
          )}

        </Modal.Footer>
      </Modal>


      {/* Modal de Confirmación */}
      <Modal show={confirmationModal} onHide={handleCloseConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación de Reserva</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Está seguro que desea reservar {seats} asiento(s) para el vuelo de {flight.departure_airport_id} a {flight.arrival_airport_id} por {flight.airline}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmationModal}>Cancelar</Button>
          <Button variant="primary" onClick={handleConfirmBooking}>Sí</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};


export default FlightDetail;
