import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { useEffect } from "react";
// import { useEffect, useState } from "react";
import Redirecting from './Redirecting';
import "./Profile.css";

const Profile = () => {
    // const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const { user, isAuthenticated } = useAuth0();
    // const [name, setName] = useState("");
    // const [email, setEmail] = useState("");
    // const [picture, setPicture] = useState("");

    useEffect(() => {
        const setInfo = async () => {
            try {
                // const userInfo = await getUserInfo(user.sub);
                // setName(userInfo.name);
                // setEmail(userInfo.email);
                // setPicture(userInfo.picture);
                console.log('In development...');
            } catch (error) {
                console.log(error);
            }
        };

        if (isAuthenticated) {
            setInfo();
        }
    }, [isAuthenticated, user.sub]);

    if (isAuthenticated) {
        // return (
        //     <div className="profile">
        //         <img src={picture} alt={name} />
        //         <h2>{name}</h2>
        //         <p>{email}</p>
        //         <p>¡Que gusto tenerte!</p>
        //     </div>
        // );
        return (<h1>In development...</h1>)
    }
};

export default withAuthenticationRequired(Profile, {
    onRedirecting: () => <Redirecting />,
    returnTo: "/"
});