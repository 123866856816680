import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import authConfig from "./auth_config.json";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={authConfig.domain}
    clientId={authConfig.clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: authConfig.audience,
      scope: authConfig.scope
    }}
    /* IMPORTANTE:
    Las siguientes líneas han sido añadidas por errores que surgen al usar getAccessTokenSilently()
    https://community.auth0.com/t/why-is-authentication-lost-after-refreshing-my-single-page-application/56276
    https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999
    */
    useRefreshTokens={true}
    cacheLocation="localstorage"
    useRefreshTokensFallback={true}
  >
    <App />
  </Auth0Provider>,
);
