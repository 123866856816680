import React, { useState, useEffect, useCallback } from 'react';
import { Container, ListGroup, Row, Col, Button } from 'react-bootstrap';
import './MyPurchases.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Redirecting from '../Auth0/Redirecting';
import { apiEndpoints } from '../../apiConfig';
import axios from 'axios';

const MyPurchases = () => {

  const [isUpdating, setIsUpdating] = useState(false);
  const [purchases, setPurchases] = useState([]);

  const { getAccessTokenSilently } = useAuth0();

  const getPurchases = useCallback(async () => {
    setIsUpdating(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios({
        method: 'GET',
        url: apiEndpoints.UserPurchases,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Obtener detalles de vuelo para cada compra
      const flightsDetails = await Promise.all(response.data.map(async (purchase) => {
        try {
          const flightResponse = await axios({
            method: 'get',
            url: apiEndpoints.FlightInfo(purchase.vuelify_flight_id),
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          return {
            ...purchase,
            flightInfo: flightResponse.data
          };
        } catch (error) {
          console.error('Error fetching flight info', error);
          return {
            ...purchase,
            flightInfo: {}
          };
        }
      }));
      setPurchases(flightsDetails);
    } catch (error) {
      console.log('Error fetching purchases', error);
    } finally {
      setIsUpdating(false);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getPurchases();
  }, [getPurchases]);

  // Función de ejemplo para el botón de actualizar (no hará nada por ahora)
  const handleRefresh = async () => {
    await getPurchases();
  };


  return (
    <Container>
      <Row className="align-items-center mb-3">
        <Col xs="auto">
          <h2>Mis Compras</h2>
        </Col>
        <Col xs="auto">
          <Button variant="outline-secondary" onClick={handleRefresh}>
            <FontAwesomeIcon icon={faSync} />
            {isUpdating && ' Actualizando...'}
          </Button>
        </Col>
      </Row>
      <ListGroup>
        {purchases.map((purchase) => (
          <ListGroup.Item key={purchase.id} className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <img src={purchase.flightInfo.airline_logo} alt={purchase.flightInfo.airline} style={{ width: '50px', marginRight: '10px' }} />
              <div>
                <strong>{purchase.flightInfo.airline}</strong> - {purchase.flightInfo.departure_airport_name} ({purchase.flightInfo.departure_airport_id}) → {purchase.flightInfo.arrival_airport_name} ({purchase.flightInfo.arrival_airport_id})<br />
                Salida: {new Date(purchase.flightInfo.departure_airport_time).toLocaleString()}<br />
                Llegada: {new Date(purchase.flightInfo.arrival_airport_time).toLocaleString()}<br />
                Precio por asiento: {new Intl.NumberFormat('es-CL', { style: 'currency', currency: purchase.flightInfo.currency }).format(purchase.flightInfo.price)}<br />
                Asientos comprados: {purchase.quantity}<br />
                <i>Comprado desde {purchase.buying_origin}</i>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {purchase.validated && (
                <Button variant="outline-primary" href={purchase.boleta} target="_blank" className="download-button">
                  <FontAwesomeIcon icon={faFileArrowDown} /> Boleta
                </Button>
              )}
              <div
                className={
                  purchase.validated ? 'purchase-complete' :
                    purchase.rejected ? 'purchase-cancelled' :
                      'purchase-in-process'
                }
                style={{ fontSize: '1.2em', fontWeight: 'bold' }}
              >
                {(purchase.seller === 1) ? "Pago Pendiente" : (purchase.validated ? 'Completada' : (purchase.rejected ? 'Cancelada' : 'En Proceso'))}
              </div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
};

export default withAuthenticationRequired(MyPurchases, {
  onRedirecting: () => <Redirecting />,
  returnTo: "/"
});