import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './FlightCard.css';

const FlightCard = ({ flight }) => {
  return (
    <Card className="flight-card my-3">
      <Card.Body className="d-flex align-items-center justify-content-between">
        <img
          src={flight.airline_logo}
          alt={`${flight.airline} logo`}
          className="airline-logo mr-3"
        />
        <div className="flight-details flex-grow-1">
          <div className="flight-times">
            <div>{new Date(flight.departure_airport_time).toLocaleString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric'})}</div>
            <div>{new Date(flight.departure_airport_time).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })} — {new Date(flight.arrival_airport_time).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}</div>
          </div>
          <div className="flight-duration">
            {flight.departure_airport_name} ({flight.departure_airport_id})
          </div>
          <div className="flight-stops">
            {Math.floor(flight.duration / 60)}h {flight.duration % 60}m
          </div>
          <div className="flight-co2">
          {flight.arrival_airport_name} ({flight.arrival_airport_id})
          </div>
          <div className="flight-price">
            {new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(flight.price)}
          </div>
        </div>
        <Button as={Link} to={`/flights/${flight.id}`} variant="primary" className="ml-auto">
          Ver detalles
        </Button>
      </Card.Body>
    </Card>
  );
};

export default FlightCard;
