import React, { useState, useEffect, useCallback } from 'react';

import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Container, Row, Col, Pagination } from 'react-bootstrap';
import FlightCard from '../components/FlightCard/FlightCard';
import { apiEndpoints } from '../apiConfig';
import { useAuth0 } from "@auth0/auth0-react";

const Recommendations = () => {
  const [flights, setFlights] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const flightsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredFlights, setFilteredFlights] = useState(flights);
  const [totalFlights, setTotalFlights] = useState(100);  // Comienza con 100 y aumenta conforme se soliciten más vuelos
  const [currentSearchParams, setCurrentSearchParams] = useState({
    departure: '',
    arrival: '',
    departureDate: '',
  });
  
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('es-CL', options);
  };  

  const { getAccessTokenSilently, user } = useAuth0();

  const [serviceStatus, setServiceStatus] = useState(null);

  const fetchServiceStatus = async () => {
    try {
      // const response = await fetch(apiEndpoints.ServiceStatus);
      const response = await fetch(apiEndpoints.ServiceStatus, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      setServiceStatus(data.operational);
    } catch (error) {
      console.error('Failed to fetch service status:', error);
      setServiceStatus(false); // Considerar el servicio como no operacional en caso de error
    }
  };

  useEffect(() => {
    fetchServiceStatus();
  }, []);

  const ServiceIndicator = () => (
    <React.Fragment>
      <span style={{ marginLeft: '5px', color: serviceStatus ? 'green' : 'red' }}>●</span>
    </React.Fragment>
  );
  

  const [tooltipVisible, setTooltipVisible] = useState(false);

  
  const paginate = (pageNumber) => {
    if (pageNumber === '...') return; // Si el usuario hace clic en los puntos suspensivos, no hagas nada.
    setCurrentPage(pageNumber);
  };
  
  
  // Renderiza la paginación
  const renderPagination = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(filteredFlights.length / flightsPerPage);
    const pageWindow = 3; // Número de páginas a mostrar alrededor de la página actual
    let startPage, endPage;
  
    if (currentPage <= pageWindow + 1) {
      startPage = 1;
      endPage = Math.min(2 * pageWindow + 1, totalPages);
    } else if (currentPage + pageWindow > totalPages) {
      startPage = Math.max(1, totalPages - 2 * pageWindow);
      endPage = totalPages;
    } else {
      startPage = currentPage - pageWindow;
      endPage = currentPage + pageWindow;
    }
  
    // Siempre agrega la primera página si tu rango de inicio es mayor que 2
    if (startPage > 2) {
      pageNumbers.push(1, '...');
    } else if (startPage === 2) {
      pageNumbers.push(1);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    // Siempre agrega la última página si tu rango de fin es menor que totalPages - 1
    if (endPage < totalPages - 1) {
      pageNumbers.push('...', totalPages);
    } else if (endPage === totalPages - 1) {
      pageNumbers.push(totalPages);
    }
  
    return (
      <Pagination className="justify-content-end">
        {pageNumbers.map((number, index) => (
          <Pagination.Item key={index} active={number === currentPage} onClick={() => paginate(number)}>
            {number}
          </Pagination.Item>
        ))}
      </Pagination>
    );
  };

  const renderLoadMoreButton = () => {
    const totalPages = Math.ceil(filteredFlights.length / flightsPerPage);
    if (currentPage === totalPages && filteredFlights.length >= totalFlights) {
      return (
        <Row className="justify-content-center mb-4">
          <Col md={4} className="text-center">
            <button className="btn btn-primary" onClick={loadMoreFlights}>Cargar más vuelos</button>
          </Col>
        </Row>
      );
    }
    return null;
  };
  

  const handleSearch = useCallback(async (searchParams) => {
  
    setCurrentSearchParams(searchParams);
    setCurrentPage(1);
    
    setLoading(true);
    setError(null);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiEndpoints.UserRecommendations(user.sub)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Something went wrong, status ' + response.status);
      }
      const data = await response.json();
      setFlights(data.flights);
      setFilteredFlights(data.flights);
      setLastUpdate(data.lastUpdate);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, user]);
  
  useEffect(() => {
    handleSearch({ departure: '', arrival: '', departureDate: '' });
  }, [handleSearch]);
  
  
  const loadMoreFlights = () => {
    setTotalFlights(current => current + 100);  // Aumentar en bloques de 100, o el número que consideres adecuado
    handleSearch(currentSearchParams);  // Suponiendo que tienes acceso a los parámetros de búsqueda actuales
  };
  

  return (
    <Container>
      <h1 className="mt-4">Vuelos Recomendados</h1>
      <p>Vuelos elegidos especialmente para ti en base a tu Itinerario Vuelify.</p>
      <p>
        <i>(Última actualización: {lastUpdate ? formatDate(lastUpdate) : '- - - '})</i>
        <span onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
          <AiOutlineInfoCircle style={{ marginLeft: '5px', cursor: 'pointer' }} />
          {tooltipVisible && (
            <div style={{
              position: 'absolute',
              zIndex: 1050,
              width: '350px',
              background: 'white',
              border: '1px solid #ddd',
              boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
              padding: '10px',
              marginLeft: '20px',
              marginTop: '-5px',
              borderRadius: '4px',
              transition: 'opacity 0.3s'
            }}>
              Utilizamos un algoritmo de recomendación en base a tus compras para encontrar los mejores vuelos para ti. <br /> <br />
              <ServiceIndicator /> <i>{serviceStatus === null ? '...' : serviceStatus ? 'Servicio disponible actualmente' : 'Servicio no disponible actualmente'} </i>
            </div>
          )}
        </span>
      </p>
  
      {loading ? (
        <p>Cargando vuelos...</p>
      ) : error ? (
        <p>Error al cargar los vuelos: {error}</p>
      ) : (
        <>
          {filteredFlights.length > 0 ? (
            <Row>
              {filteredFlights.slice((currentPage - 1) * flightsPerPage, currentPage * flightsPerPage).map(flight => (
                <Col key={flight.id} md={12}>
                  <FlightCard flight={flight} />
                </Col>
              ))}
            </Row>
          ) : (
            <Row>
              <Col className="text-center">
                <p>Cuando compres tu primer vuelo empezaremos a generar recomendaciones :)</p>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div style={{ marginTop: '20px', marginBottom: '20px' }}></div>
              {filteredFlights.length > flightsPerPage && renderPagination()}
            </Col>
          </Row>
            {renderLoadMoreButton()}
        </>
      )}
    </Container>
  );
  
  
};

export default Recommendations;