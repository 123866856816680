import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import FlightList from './pages/FlightList';
import FlightDetail from './pages/FlightDetail';
import MyPurchases from './components/MyPurchases/MyPurchases';
import NavBar from './components/NavBar/NavBar';
import HomePage from './pages/HomePage';
import Profile from './components/Auth0/Profile';
import Recommendations from './pages/Recommendations';
import Webpay from './pages/Webpay';
import 'bootstrap/dist/css/bootstrap.min.css';

function ConditionalMargin() {
  const location = useLocation();
  const style = location.pathname === '/' ? {} : { marginBottom: '20px' };
  return <div style={style}></div>;
}


function App() {
  return (
    <Router>
      <NavBar />
      <ConditionalMargin />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/flights" element={<FlightList />} />
        <Route path="/flights/:id" element={<FlightDetail />} />
        <Route path="/my-purchases" element={<MyPurchases />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/recommendations" element={<Recommendations />} />
        <Route path="/webpay" element={<Webpay/>} />
      </Routes>
    </Router>
  );
}

export default App;
