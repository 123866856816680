import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from 'react-bootstrap';
import './Button.css';

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return <Button variant="custom-button" onClick={() => loginWithRedirect()}>Iniciar sesión</Button>;
};

export default LoginButton;