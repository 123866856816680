import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { apiEndpoints } from '../apiConfig';
import { Row, Col, Button } from 'react-bootstrap';
import './HomePage.css';
import { useAuth0 } from "@auth0/auth0-react";

const Webpay = () => {
    const location = useLocation();
    const [paymentResult, setPaymentResult] = useState("");
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token_ws = params.get('token_ws');

        console.log("params: ", params);
        console.log("token_ws: ", token_ws);

        const verifyPayment = async () => {
            try {
                // const user = await user();
                // console.log(user);
                const token_auth = await getAccessTokenSilently();
                // const response = await axios.post(`${apiEndpoints.WebpayReturn}`, { "token_ws": token_ws });
                const response = await axios.post(
                    `${apiEndpoints.WebpayReturn}`,
                    { "token_ws": token_ws },
                    { headers: { Authorization: `Bearer ${token_auth}` } }  // Aquí agregamos el header
                );
                const data = response.data;
                const message = data.response;
                console.log('Payment verification:', message);
                setPaymentResult(message);
            } catch (error) {
                console.error('Error verifying payment', error);
            }
        };

        if (token_ws) {
            verifyPayment();
        }
    }, [location, getAccessTokenSilently]);

    const sectionStyle = {
        width: "100%",
        height: "100vh",
        backgroundImage: 'url(/venezia.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      };

    return (
        <div style={sectionStyle}>
            <Row className="justify-content-md-center align-items-start pt-5">
                <Col md={6} className="text-center">
                <h1 className="display-3 fw-bold" style={{ color: 'black' }}>{paymentResult}</h1>
                <p className="fs-4" style={{ color: 'black' }}>Retorno de Webpay</p>
                <Row className="justify-content-md-center align-items-start pt-5">
                    <Button
                    className="custom-button"
                    size="lg"
                    href="/flights"
                    >
                    Buscar Vuelos
                    </Button>
                    <Button
                    className="custom-button"
                    size="lg"
                    href="/my-purchases"
                    >
                    Ver Mis Compras
                    </Button>
                </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Webpay;