import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from 'react-bootstrap';
import './Button.css';

const LogoutButton = () => {
    const { logout } = useAuth0();

    return (
        <Button variant="custom-button" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
            Cerrar sesión
        </Button>
    );
};

export default LogoutButton;