// Parametrización API y más

// url de la API Backend
// const BASE_URL = "http://localhost:3000";
// const BASE_URL = "https://e0valeramirez.tech";
// const BASE_URL = "https://0en24gm0ki.execute-api.us-east-2.amazonaws.com/Proyecto-api";
const BASE_URL = "https://api.e0valeramirez.tech"

export const apiEndpoints = {

  // Obtener todos los vuelos para listar
  AllFlights: `${BASE_URL}/flights`,

  // Obtener la info de un vuelo en específico
  FlightInfo: flight_id => `${BASE_URL}/flights/${flight_id}`,

  // Obtener todas las compras de un usuario en específico
  UserPurchases: `${BASE_URL}/requests_by_user`,

  // Enviar la reserva de un vuelo
  PostRequest: `${BASE_URL}/post_request`,

  // Obtener todas las recomendaciones de un usuario en específico
  UserRecommendations: user_id => `${BASE_URL}/recommendations/${user_id}`,

  // Obtener el estado del servicio de recomendaciones
  ServiceStatus: `${BASE_URL}/heartbeat`,

  WebpayReturn: `${BASE_URL}/webpay-return`

};
