import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from '../Auth0/LoginButton';
import LogoutButton from '../Auth0/LogoutButton';

const NavBar = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>Vuelify</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/flights">
              <Nav.Link>Buscar Vuelos</Nav.Link>
            </LinkContainer>
            {isAuthenticated && (
              <>
                <LinkContainer to="/my-purchases">
                  <Nav.Link>Mis Compras</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/recommendations">
                  <Nav.Link>Recomendaciones</Nav.Link>
                </LinkContainer>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
        {!isAuthenticated ? <LoginButton /> : <LogoutButton />}
      </Container>
    </Navbar>
  );
};

export default NavBar;
